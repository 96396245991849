export const CONFIG = {
  API_URL: "https://blue-api.trackpac.io",
  mapboxAccessToken:
    "pk.eyJ1IjoiYmZnbmVpbCIsImEiOiJjbGR2azExeW4wZ28yM29tZm4xZjJwdHFxIn0.Mdv--BkZwKSw-pdTRMQdPg",
  mapStyle: "mapbox://styles/bfgneil/clkha3odl008i01qpeqws6d21",
  siteTitle: "Blue Shield",
  siteTitleDescription:
    "Low cost GPS asset tracking for the Greater Toronto Area",
  battery_minMV: 2300,
  battery_maxMV: 3000,
  shareUrl: "https://track.blueshieldtech.ca/share/",
  dashboardShareUrl: "https://track.blueshieldtech.ca/share/dashboard/",
  websiteURL: "https://track.blueshieldtech.ca",
  Auth0Domain: "blueshieldtechnologies.us.auth0.com",
  Auth0ClientId: "dZUTU7jKImmgvdhbU9NZoXqEuKP3xCV7",
  Auth0Audience: "http://api.blueshieldtech.ca",
  primaryColour: "#004AAD",
  primaryColourRGB: {
    r: 0,
    g: 74,
    b: 173,
  },
  secondaryColour: "#0d00ad",
  defaultTheme: "light",
  defaultLanguage: "en",
  locale: "en",
  organisation_enabled: false,
  billing_enabled: false,
  manual_add: false,
  self_host: false,
  feedback_enabled: false,
  feedback_url: "https://trackpac.canny.io/feature-device-requests",
  show_geofences: true,
  api_key_enabled: false,
  register_card_enabled: false,
  defaultDateFormat: "MM/DD/YYYY",
  show_buy_link: false,
  buy_link: "https://hexaspot.com/collections/trackpac",
};
